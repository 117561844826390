const AnchorInputScroll = (event, elementId) => {
  event.preventDefault()
  const element = document.getElementById(elementId)
  element.scrollIntoView({
    behavior: "smooth",
    block: "center",
  })
  element.focus({ preventScroll: true })
}

export default AnchorInputScroll
