import React from "react"
import FeaturesSplit from "../FeaturesSplit"
import animx_image from "../../../assets/images/graph_editor.png"
import wgen_image from "../../../assets/images/hair_1.png"
import scenelib_image from "../../../assets/images/light_2.png"

const sectionHeader = {
  title: (
    <>
      Wizart <span className="text-color-primary">DCC</span> is better with
      extensions
    </>
  ),
  paragraph:
    "On top of the core USD editing functionality we also include the powerful extensions",
}

//   video item example
//   {
//     title: "",
//     name: "",
//     description: (
//       <>
//       </>
//     ),
//     video: true,
//     videoId: "", youtube video id
//     imageSrc: "", - thumbnail if need
//   }

const featureItems = [
  {
    title: "Open Source Animation",
    name: "ANIMX",
    description: (
      <>
         You can author animation using <a href="https://github.com/Autodesk/animx">AnimX</a> compatible animation curves animation engine. 
         Using Graph Editor, so you can edit animation right inside the app.
      </>
    ),
    imageSrc: animx_image,
    video: true, 
    videoId: "Kh_Epd-UPDU"
  },
  {
    title: "Open Source Hair System",
    name: "WGEN",
    description: (
      <>
        Wgen is node-based hair system with support of industry standard <a href="https://github.com/AcademySoftwareFoundation/OpenShadingLanguage">
          OSL
        </a>. OSL networks allow you procedurally change curves shape or change hair color using textures.
        Using included toolset for sculpting the hair, an artist can create any haircut for your character.
      </>
    ),
    imageSrc: wgen_image,
    video: true,
    videoId: "k6FaTvMmPYM"
  },
  {
    title: "Open Source Lighting Toolset",
    name: "SCENELIB",
    description: (
      <>
        Scenelib is a powerful node-based system for procedural scene description right-before render.
        Lighting artists can visually create a sequence or
        shot lighting using the SceneLib node graph. The resulting scenegraph data could be
        rendered using various third-party renderers.
      </>
    ),
    imageSrc: scenelib_image,
    video: true,
    videoId: "jzzCV-r927U"
  },
]

const Index = () => {
  return (
    <>
      <FeaturesSplit
        invertMobile
        topDivider
        imageFill
        className="illustration-section-02"
        sectionHeader={sectionHeader}
        featureItems={featureItems}
      />
    </>
  )
}

export default Index
