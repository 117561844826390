import React from "react"

// Layouts
import LayoutDefault from "../../layouts/LayoutDefault"

// Views
import Home from "../../views/en/Home"

const En = props => {
  return (
    <LayoutDefault {...props}>
      <Home {...props} />
    </LayoutDefault>
  )
}

export default En
