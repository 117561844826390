import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import { Link } from "gatsby"

const useStyles = makeStyles({
  root: {
    flex: "0 0 33.333333%",
    backgroundColor: "#25282c",
    height: 290,
    "&:hover": {
      backgroundColor: "#505050",
    },
  },
  media: {
    height: 225,
  },
})

const Index = props => {
  const classes = useStyles()

  return (
    <Card className={classes.root} {...props}>
      <Link to={props.link}>
        <CardMedia className={classes.media} image={props.image} />
        <CardContent>
          <div className={"center-content"}>
            <p style={{ color: "#9ca9b3" }} className={"text-sm"}>
              {props.name}
            </p>
          </div>
        </CardContent>
      </Link>
    </Card>
  )
}

export default Index
