import React from "react"

import DccImage from "assets/images/dcc.jpg"
import CardsIndustry from "./CardsIndustry"
import blender_live_share_image from "assets/images/blender_live_share.png"
import usd_editing_image from "assets/images/usd_editing.png"
import wgen_image from "assets/images/hair_1.png"
import wgen_variants_image from "assets/images/hair_variants.png"
import paint_instances_tool_image from "assets/images/paint_instances_tool.png"

const Index = props => {
  const location = props.location !== undefined ? props.location : {}
  const pathname = location.pathname !== undefined ? location.pathname : ""

  const isRuPath = pathname.indexOf("/ru") !== -1
  const path = isRuPath === true ? "/ru" : "/en"

  const title_text = (
    <>
      What if Wizart <span className="text-color-primary">DCC</span> could solve
      your problem?
    </>
  )

  const title_text_ru = (
    <>
      {" "}
      Что если Wizart <span className="text-color-primary">DCC</span> сможет
      решить вашу проблему ?
    </>
  )

  const paragraph_text =
    "..or probably could solve it in the future, follow our updates"
  const paragraph_text_ru =
    "..или возможно сможет ее решить в будущем, следите за нашими обновлениями"

  const sectionHeader = {
    title: isRuPath ? title_text_ru : title_text,
    paragraph: isRuPath ? paragraph_text_ru : paragraph_text,
  }

  const cardsData = [
    {
      image: usd_editing_image,
      name: isRuPath === true ? "USD Пайплайн" : "USD Pipeline",
      link: `${path}/solutions/usd_pipeline`,
    },
    {
      image: paint_instances_tool_image,
      name: isRuPath === true ? "Сборка Сцен" : "Scene Assembly",
      link: `${path}/solutions/scene_assembly`,
    },
    {
      image: wgen_image,
      name: isRuPath === true ? "Создание Волос" : "Hair",
      link: `${path}/solutions/hair`,
    },
    {
      image: blender_live_share_image,
      name: isRuPath === true ? "Blender Пайплайн" : "Blender Pipeline",
      link: `${path}/solutions/blender_pipeline`,
    },
    // {
    //   image: wgen_variants_image,
    //   name: isRuPath === true ? "Цифровые аватары" : "Digital Avatars",
    //   link: `${path}/solutions/digital_avatars`,
    // },
    // {
    //   image: DccImage,
    //   name: isRuPath === true ? "Мобильный AR" : "Mobile AR",
    //   link: `${path}/solutions/mobile_ar`,
    // },
  ]

  return (
    <CardsIndustry
      cardsData={cardsData}
      sectionHeader={sectionHeader}
      invertMobile
      topDivider
    />
  )
}

export default Index
