import React from "react"
import classNames from "classnames"
import { SectionSplitProps } from "../../utils/SectionProps"
import SectionHeader from "./partials/SectionHeader"
import Card from "../elements/Card"
import Grid from "@material-ui/core/Grid"

const propTypes = {
  ...SectionSplitProps.types,
}

const defaultProps = {
  ...SectionSplitProps.defaults,
}

const Cards = props => {
  return props.cards.map((cardProps, index) => (
    <Grid item xs={12} sm={6} md={4}>
      <Card {...cardProps} key={index} />
    </Grid>
  ))
}

const Index = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  featureItems,
  cardsData,
  sectionHeader,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  )

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  )

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content"
            style={{
              paddingBottom: 40,
            }}
          />
          <Grid
            container
            direction="row"
            alignContent={"center"}
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Cards cards={cardsData} />
          </Grid>
        </div>
      </div>
    </section>
  )
}

Index.propTypes = propTypes
Index.defaultProps = defaultProps

export default Index
