import React from "react"
import classNames from "classnames"
import { SectionProps } from "../../../utils/SectionProps"
import ButtonGroup from "../../elements/ButtonGroup"
import Button from "../../elements/Button"
import Image from "../../elements/Image"
import DccImage from "../../../assets/images/dcc.jpg"
import AnchorInputScroll from "../../../utils/AnchorInputScroll"

import RequestAccessButton from "../RequestAccessButton"
import Popup from "../../elements/Popup"

const propTypes = {
  ...SectionProps.types,
}

const defaultProps = {
  ...SectionProps.defaults,
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  )

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  )

  const scrollToSubscribeInput = event => {
    AnchorInputScroll(event, "subscribe")
  }

  const openSourcePopupText =
    "We are not open-source yet. But we are working hard to make this possible"

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Wizart <span className="text-color-primary">DCC Platform</span>
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                <Popup popupText={openSourcePopupText}>
                  <span className="text-color-primary">Open Source*</span>
                </Popup>{" "}
                extensible 3D editor based on{" "}
                <a href="https://graphics.pixar.com/usd">
                  Pixar's Universal Scene Description (USD){" "}
                </a>
              </p>
            </div>
            <div className={"container-xs"}>
              <div className="center-content">
                <ButtonGroup>
                  <Button
                    tag="a"
                    wideMobile
                    onClick={event => scrollToSubscribeInput(event)}
                  >
                    Subscribe to Newsletter
                  </Button>
                  <RequestAccessButton name={"Request Access"} />
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            <Image
              className="has-shadow"
              src={DccImage}
              alt="DCC"
              width={896}
              height={504}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

Hero.propTypes = propTypes
Hero.defaultProps = defaultProps

export default Hero
