import React from "react"
import classNames from "classnames"
import { SectionTilesProps } from "../../../utils/SectionProps"
import SectionHeader from "../partials/SectionHeader"
import Image from "../../elements/Image"
import FeatureIcon3 from "../../../assets/images/feature-tile-icon-03.svg"
import QtLogo from "../../../assets/images/qt-logo.svg"
import CppLogo from "../../../assets/images/cpp-logo.svg"
import OpenSource from "../../../assets/images/open-source-logo.svg"
import WindowsLogo from "../../../assets/images/windows-logo.svg"
import LinuxLogo from "../../../assets/images/linux-logo.svg"
import AppleLogo from "../../../assets/images/apple-logo.svg"
import UsdLogo from "../../../assets/images/usd-logo.svg"

const propTypes = {
  ...SectionTilesProps.types,
}

const defaultProps = {
  ...SectionTilesProps.defaults,
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  )

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  )

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  )

  const sectionHeader = {
    title: (
      <>
        Build your <span className="text-color-primary">USD</span> pipeline
      </>
    ),
    paragraph:
      "We believe the pipeline could be better if you build it on top of the USD and Hydra/Storm.  And we think you need a direct USD editor with industry-standard UI. And it would be better if its free and open source.",
  }

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={OpenSource}
                      alt="Open Source"
                      width={64}
                      height={64}
                      style={{
                        height: 64,
                      }}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Open Source*</h4>
                  <p className="m-0 text-sm">
                    We are working hard to make the product available as open-source project.
                    Open-source is safer for your company since you can
                    fix and debug it yourself. 
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div
                    className="features-tiles-item-image mb-16"
                    style={{
                      marginRight: 5,
                    }}
                  >
                    <Image
                      src={WindowsLogo}
                      alt="Windows Logo"
                      width={64}
                      height={64}
                      style={{
                        height: 64,
                      }}
                    />
                  </div>
                  <div
                    className="features-tiles-item-image mb-16"
                    style={{
                      marginRight: 5,
                    }}
                  >
                    <Image
                      src={LinuxLogo}
                      alt="Linux Logo"
                      width={64}
                      height={64}
                      style={{
                        height: 64,
                      }}
                    />
                  </div>
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={AppleLogo}
                      alt="Apple Logo"
                      width={64}
                      height={64}
                      style={{
                        height: 64,
                      }}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Cross Platform</h4>
                  <p className="m-0 text-sm">
                    We support Windows, Linux and Mac OS.
                  </p>
                </div>
              </div>
            </div>

            {/* <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={FeatureIcon3}
                      alt="Free Logo"
                      width={64}
                      height={64}
                      style={{
                        height: 64,
                      }}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Free</h4>
                  <p className="m-0 text-sm">
                    It's free for commercial work. Free to use on the render
                    farm. Free to use in the cloud.
                  </p>
                </div>
              </div>
            </div> */}

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={UsdLogo}
                      alt="Usd Logo"
                      width={64}
                      height={64}
                      style={{
                        height: 64,
                      }}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">USD</h4>
                  <p className="m-0 text-sm">
                    No USD usage assumptions in the editor. USD library is
                    unmodified, could be swapped with your fork. You can use
                    your own ArResolver.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={QtLogo}
                      alt="Qt Logo"
                      width={64}
                      height={64}
                      style={{
                        height: 64,
                      }}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Industry Standard UI</h4>
                  <p className="m-0 text-sm">
                    The Qt-based UI is designed in a way that artists feel at
                    home, like in any other industry-standard DCC. Don't
                    reinvent the wheel.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={CppLogo}
                      alt="CPP Logo"
                      width={64}
                      height={64}
                      style={{
                        height: 64,
                      }}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Extensible</h4>
                  <p className="m-0 text-sm">
                    Extensible with Python/C++ plugins. Qt/Pyside2 for the UI.
                    Follow <a href="https://vfxplatform.com/">VFX Platform</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

FeaturesTiles.propTypes = propTypes
FeaturesTiles.defaultProps = defaultProps

export default FeaturesTiles
