import React from "react"
import classNames from "classnames"
import { SectionSplitProps } from "../../utils/SectionProps"
import SectionHeader from "./partials/SectionHeader"
import Image from "../elements/Image"
import ModalVideo from "../elements/ModalVideo"

const propTypes = {
  ...SectionSplitProps.types,
}

const defaultProps = {
  ...SectionSplitProps.defaults,
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  sectionHeader,
  featureItems,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  )

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  )

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  )

  const mediaComponent = (item, index) => {
    if (item.video === true) {
      return <ModalVideo videoId={item.videoId} thumbnailSrc={item.imageSrc} />
    }
    if (item.imageSrc !== undefined) {
      return (
        <Image
          src={item.imageSrc}
          alt={`Features split ${index}`}
          width={528}
          height={396}
        />
      )
    }
    return undefined
  }

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            {featureItems.map((item, index) => (
              <div className="split-item" key={`item-${index}`}>
                <div
                  className="split-item-content center-content-mobile"
                  data-reveal-container=".split-item"
                >
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    {item.title}
                  </div>
                  <h3 className="mt-0 mb-12">{item.name}</h3>
                  <p className="m-0">{item.description}</p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                >
                  {mediaComponent(item, index)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

FeaturesSplit.propTypes = propTypes
FeaturesSplit.defaultProps = defaultProps

export default FeaturesSplit
