import React from "react"
// import sections
import Hero from "../../components/layout/en/Hero"
import FeaturesSplitSection from "../../components/layout/en/FeaturesSplitSection"
import FeaturesTiles from "../../components/layout/en/FeaturesTiles"
import CardsIndustryBlock from "../../components/layout/CardsIndustryBlock"
import Cta from "../../components/layout/en/Cta"

const Home = props => {
  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      <CardsIndustryBlock {...props} />
      <FeaturesSplitSection />
      <Cta split topDivider />
    </>
  )
}

export default Home
